<template>
  <v-card>
    <v-card-title class="headline sdark">
      <v-layout align-center>
        <v-flex>
          <span class="primary--text text-uppercase font-weight-medium"
            >invite user</span
          >
        </v-flex>
        <v-flex shrink>
          <v-btn color="primary" icon flat @click.native="onClickClose">
            <v-icon>close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <v-container grid-list-md>
        <v-layout row wrap align-center>
          <v-flex xs12>
            <v-container text-xs-center>
              <v-alert :value="error" outline color="error">{{
                error
              }}</v-alert>
              <span v-show="!error" class="title font-weight-regular"
                >Enter the email address of the user to invite.</span
              >
            </v-container>
          </v-flex>

          <v-flex xs12>
            <v-form ref="form">
              <v-text-field
                color="primary"
                name="email"
                clearable
                label="Email"
                id="email"
                type="email"
                v-model.trim="email"
                @focus="onDismissed"
                required
                :rules="[...rules.emailRules, uniqueEmail]"
                @input="validateUniqueEmail()"
              ></v-text-field>
            </v-form>
          </v-flex>
          <v-flex xs12 class="pt-3 text-xs-center">
            <d-submit
              @onSubmit="onClickInvite"
              v-bind="{
                status,
                error,
                doneValue: 'Invitation sent',
                errorValue: 'Invitation failed'
              }"
            ></d-submit>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { FormMixin } from '@/components/Mixins'
import { mapState } from 'vuex'

export default {
  name: 'InvitationUserCard',
  mixins: [FormMixin],
  data: () => ({
    uniqueEmail: false,
    email: null
  }),
  computed: {
    ...mapState('users', ['status', 'error', 'usersList']),
    existingEmails() {
      return this.usersList.map(user => user.email)
    }
  },
  methods: {
    validateUniqueEmail() {
      this.uniqueEmail =
        !this.existingEmails.includes(this.email) ||
        'Email already used for another user'
    },
    onClickInvite() {
      this.$emit('onClickInvite', this.email)
      this.$refs.form.reset()
    },
    onClickClose() {
      this.$refs.form.reset()
      this.$emit('clickClose')
    },
    onDismissed() {
      this.$refs.form.reset()
      return this.$store.dispatch('users/DISMISS')
    }
  }
}
</script>
