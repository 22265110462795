<template>
  <v-card data-cy="user-site-user-card">
    <v-card-title class="headline sdark">
      <v-layout align-center>
        <v-flex>
          <span class="primary--text text-uppercase font-weight-medium"
            >Site association</span
          >
        </v-flex>
        <v-flex shrink>
          <v-btn
            color="primary"
            icon
            flat
            @click.native="onClickClose"
            data-cy="close"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <v-flex xs12>
        <v-alert
          dismissible
          @input="dismiss"
          :value="error"
          outline
          color="primary"
          >{{ error }}</v-alert
        >
      </v-flex>
      <v-layout row align-center>
        <v-flex xs6 pr-2>
          <v-select
            no-data-text="No available sites"
            :items="filteredSites"
            item-text="name"
            item-value="id"
            :value="selectedSiteId"
            @change="setSelectedSite"
            label="Sites"
            :disabled="loading"
          ></v-select>
        </v-flex>
        <v-flex xs4 pl-2>
          <v-select
            :items="roles"
            item-text="name"
            :value="selectedRole"
            @change="setSelectedRole"
            label="Roles"
            :disabled="loading"
          ></v-select>
        </v-flex>
        <v-flex shrink>
          <v-btn
            flat
            icon
            color="primary"
            @click="addSiteAssociation"
            :disabled="loading || !this.selectedSiteId"
          >
            <v-icon>add</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-text>
      <v-data-table
        :headers="headersUser"
        :items="processedUserSites"
        hide-actions
        data-cy="user-sites-table"
        :loading="loading"
      >
        <template slot="items" slot-scope="props">
          <tr :data-cy="`${props.item.username}-row`">
            <td>{{ props.item.site }}</td>
            <td>{{ props.item.role }}</td>
            <td>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    small
                    @click="delSiteAssociation(props.item)"
                    data-cy="delete-user"
                  >
                    <v-icon small>delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete Site Association</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions></v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'

const LOADING_WAIT_TIMEOUT = 500

const props = {
  user: {
    type: Object
  }
}

export default {
  name: 'UserSitesCard',
  props,
  data: () => ({
    headersUser: [
      { text: 'Site', value: 'site', align: 'left' },
      { text: 'Role', value: 'role' },
      { text: 'Action', value: 'action' }
    ],
    rolesMap: {
      1: 'site manager',
      2: 'operator',
      3: 'guest'
    },
    roles: [
      { name: 'Site Manager', value: 'site_manager' },
      { name: 'Operator', value: 'operator' },
      { name: 'Guest', value: 'guest' }
    ],
    loading: false,
    selectedSiteId: '',
    selectedRole: 'guest',
    removalSelectedSiteId: '',
    userSites: [],
    existingUser: {
      name: null,
      email: null,
      company: '',
      role: 'guest',
      username: null
    }
  }),
  computed: {
    ...mapGetters('sites', ['sitesSet']),
    ...mapState('sites', ['error', 'status']),
    sites() {
      return Object.values(this.sitesSet)
    },
    filteredSites() {
      let mappedSites = this.userSites.map(record => record.site_id)
      //get all sites which match the client id of the user
      return this.sites.filter(site => {
        return (
          !mappedSites.includes(site.id) &&
          this.existingUser.client_id === site.client_id
        )
      })
    },
    processedUserSites() {
      return this.userSites.map(u =>
        Object.assign(u, {
          role: u.role,
          site: this.sitesSet[u.site_id] && this.sitesSet[u.site_id]['name']
        })
      )
    }
  },
  methods: {
    ...mapActions('sites', [
      'ADD_SITE_ASSOCIATION',
      'FETCH_USER_SITES',
      'DELETE_SITE_ASSOCIATION'
    ]),
    setSelectedSite(siteId) {
      this.selectedSiteId = siteId
    },
    setSelectedRole(role) {
      this.selectedRole = role
    },
    setRemovalSelectedSite(siteId) {
      this.removalSelectedSiteId = siteId
    },
    async addSiteAssociation() {
      this.loading = true
      await this.ADD_SITE_ASSOCIATION({
        user_id: this.existingUser.id,
        site_id: this.selectedSiteId,
        role: this.selectedRole
      })

      setTimeout(async () => {
        this.userSites = await this.FETCH_USER_SITES(this.existingUser.id)
        this.selectedSiteId = null
        this.loading = false
      }, LOADING_WAIT_TIMEOUT)
    },
    async delSiteAssociation({ site_id, user_id }) {
      this.loading = true
      await this.DELETE_SITE_ASSOCIATION({ site_id, user_id })
      setTimeout(async () => {
        this.userSites = await this.FETCH_USER_SITES(user_id)
        this.loading = false
      }, LOADING_WAIT_TIMEOUT)
    },
    onClickSave() {
      const { name, username, password, email, role } = this.newUser
      this.$emit('onCreate', { name, username, password, email, role })
    },
    onClickClose() {
      this.$emit('onClose')
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    dismiss(v) {
      if (!v) {
        this.$store.commit('sites/SET_ERROR', null)
      }
    }
  },
  beforeDestroy() {
    this.$emitter.off('EDIT_USER_SITES')
  },
  mounted() {
    this.$emitter.on('EDIT_USER_SITES', async data => {
      this.existingUser = data.user
      this.userSites = await this.FETCH_USER_SITES(data.user.id)
    })
  }
}
</script>
