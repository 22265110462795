




















import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import UserManagement from '@/components/User/UserManagement.vue'
import ClientManagement from '@/components/User/ClientManagement.vue'

export default Vue.extend({
  name: 'UserManagementPage',
  components: { UserManagement, ClientManagement },
  data: () => ({}),
  computed: {
    ...mapState('sites', ['activeSiteId']),
    ...mapState('users', ['error', 'status', 'userList', 'user']),
    ...mapGetters('users', ['isAuthorized'])
  },
  methods: {},
  async mounted(): Promise<void> {
    if (this.isAuthorized('site_manager')) {
      await this.$store.dispatch('users/fetchListOfUsers')
    }
    if (this.isAuthorized('admin')) {
      await this.$store.dispatch('users/FETCH_CLIENT')
    } else {
      await this.$store.dispatch(
        'users/FETCH_CLIENT',
        this.user && this.user.client_id
      )
    }
  }
})
