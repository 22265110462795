<template>
  <div>
    <d-confirm-dialog
      v-if="dialogConfirm"
      :value="dialogConfirm"
      :title="deleteText"
      @cancel="dialogConfirm = false"
      @confirm="clickConfirm"
      :error="error"
      :status="status"
      :detail="deleteDetailText"
    />
    <v-dialog v-model="dialogClient" max-width="500px" v-if="dialogClient">
      <client-edit
        v-if="dialogClient"
        @onSave="saveClient"
        :client="client"
        :visible="dialogClient"
        @clickClose="dialogClient = false"
      />
    </v-dialog>
    <v-card-title>
      <v-layout row wrap>
        <v-flex shrink style="width: 300px">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            hide-details
          ></v-text-field>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="clientsList"
        hide-actions
        data-cy="clients-table"
        :search="search"
      >
        <template slot="items" slot-scope="props">
          <tr :data-cy="`${props.item.name}-row`">
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.contact }}</td>
            <td>{{ props.item.email }}</td>
            <td>{{ props.item.address }}</td>
            <td>{{ props.item.city }}</td>
            <td>{{ props.item.state }}</td>
            <td>
              <v-toolbar color="transparent" flat dense>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      icon
                      v-if="isAuthorized('admin')"
                      @click="clickEdit(props.item)"
                      data-cy="edit-client"
                    >
                      <v-icon small>edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Client</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      icon
                      v-if="isAuthorized('admin')"
                      @click="clickDelete(props.item)"
                      data-cy="delete-client"
                    >
                      <v-icon small>delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Client</span>
                </v-tooltip>
              </v-toolbar>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
import { DConfirmDialog } from '@/components/Base'
import { ClientEdit } from '@/components/User/Cards'
import { mapGetters, mapState } from 'vuex'

const props = {}

export default {
  name: 'ClientTable',
  props,
  components: {
    ClientEdit,
    DConfirmDialog
  },
  data: () => ({
    search: '',
    client: null,
    dialogClient: false,
    dialogConfirm: false,
    selectedClient: null,
    deleteText: '',
    deleteDetailText: null,
    headers: [
      { text: 'Id', value: 'id', align: 'left' },
      { text: 'Name', value: 'name', align: 'left' },
      { text: 'Contact', value: 'contact' },
      { text: 'Email', value: 'email' },
      { text: 'Address', value: 'address' },
      { text: 'City', value: 'city' },
      { text: 'State', value: 'state' },
      { text: 'Actions', value: 'name', sortable: false }
    ]
  }),
  computed: {
    ...mapState('users', ['status', 'error', 'clientsList']),
    ...mapGetters('users', ['isAuthorized']),
    ...mapGetters('sites', ['sitesList']),
    ...mapState({ auth: state => state.auth })
  },
  methods: {
    async saveClient(client) {
      await this.$store.dispatch('users/SAVE_CLIENT', client)
      if (this.status === 'OK') {
        return setTimeout(() => (this.dialogClient = false), 500)
      }
    },
    clickEdit(client) {
      this.client = client
      this.dialogClient = true
    },
    clickDelete(client) {
      const { name } = client || {}
      this.$store.commit('users/SET_ERROR', null)
      this.$store.commit('users/SET_STATUS', null)
      this.dialogConfirm = true
      this.selectedClient = client
      this.deleteText = `Delete client ${name}`
      this.deleteDetailText = null

      const sitesToBeRemoved = this.sitesList
        .filter(s => s.client_id === client.id)
        .map(s => s.name)

      if (sitesToBeRemoved.length != 0) {
        this.deleteDetailText = `${
          sitesToBeRemoved.length == 1
            ? 'One site'
            : 'All ' + sitesToBeRemoved.length + ' sites'
        } associated to by ${name} will be removed:</p><ul>`
        for (let site of sitesToBeRemoved) {
          this.deleteDetailText += `<li>${site}</li>`
        }
        this.deleteDetailText += '</ul>'
      }
    },
    async clickConfirm() {
      await this.$store.dispatch('users/DELETE_CLIENT', this.selectedClient)
      if (this.status === 'OK') {
        return setTimeout(() => this.$router.push({name: "DataLoader"}), 500)
      } else {
        console.error(this.error)
      }
    }
  },
  watch: {
    dialog(v) {
      if (!v) {
        this.selectedUser = null
      }
    }
  }
}
</script>
