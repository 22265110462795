<template>
  <v-card data-cy="update-user-card">
    <v-card-title class="headline sdark">
      <v-layout align-center>
        <v-flex>
          <span class="primary--text text-uppercase font-weight-medium"
            >{{ user && user.id ? 'edit' : 'create' }} user</span
          >
        </v-flex>
        <v-flex shrink>
          <v-btn color="primary" icon flat @click.native="onClickClose">
            <v-icon>close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <v-container grid-list-md>
        <v-layout row wrap align-center>
          <v-flex xs12>
            <v-alert :value="error" outline color="primary">
              {{ error && error.message }}
            </v-alert>
          </v-flex>
          <div
            v-if="isAdminUpgrade || isUpdatedClient"
            id="site-association-alert"
          >
            <strong>{{isAdminUpgrade ? 'Upgrade to Admin Role' : 'Updated Client'}}</strong>
            <div id="alert-text">The existing site associations for the edited user will be removed.</div>
         </div>
          <v-flex xs12>
            <d-form
              v-model="valid"
              @onSubmit="onClickSave"
              @onCancel="onClickClose"
              includeCancel
              v-bind="{ status, error }"
            >
              <v-text-field
                color="primary"
                name="name"
                label="Name"
                id="name"
                v-model="existingUser.name"
                :rules="[rules.required, rules.minName]"
              />
              <v-text-field
                color="primary"
                name="email"
                label="Email"
                :rules="[...rules.emailRules, uniqueEmail]"
                id="email"
                type="email"
                v-model="existingUser.email"
                @input="validateUniqueEmail()"
              />
              <v-text-field
                color="primary"
                name="username"
                label="Username"
                id="username"
                :rules="[rules.required, rules.minName, uniqueUsername]"
                v-model="existingUser.username"
                @input="validateUniqueUsername()"
              />
              <v-text-field
                color="primary"
                name="company"
                label="Company"
                id="company"
                v-model="existingUser.company"
                required
              />
              <v-text-field
                color="primary"
                name="phone"
                label="Phone"
                id="phone"
                type="phone"
                v-model="existingUser.phone"
              />
              <v-select
                name="role"
                label="Role"
                v-model="existingUser.role"
                :items="roleOptions"
              />
              <v-select
                v-if="existingUser.role !== 'admin' && isAuthorized('admin')"
                name="client"
                label="Client"
                v-model="existingUser.client_id"
                :items="clients"
                :rules="[rules.required]"
              />
              <SubHeader label="Planning Tool" />
              <v-tooltip
                  :open-delay="tooltipDelay"
                  color="sdark"
                  max-width="430"
                  right
              >
                <v-switch
                    slot="activator"
                    class="py-0"
                    label="Show Component List"
                    v-model="existingUser.show_plan_part_lists"
                    color="primary"
                    type="Boolean"
                />
                <span>
                  {{
                        existingUser.show_plan_part_lists
                            ? 'Hide'
                            : 'Show'
                      }}
                  button to generate component list
                </span>
              </v-tooltip>
            </d-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { FormMixin } from '@/components/Mixins'
import SubHeader from '../../Forms/SubHeader'

const props = {
  user: {
    type: Object
  }
}

export default {
  name: 'user-edit-card',
  props,
  mixins: [FormMixin],
  components: { SubHeader },
  data: () => ({
    valid: false,
    currentRole: 'user',
    currentClientID: null,
    existingUser: {
      name: null,
      email: '',
      company: '',
      role: 'user',
      username: '',
      show_plan_part_lists: false,
    },
    uniqueEmail: false,
    uniqueUsername: false
  }),
  computed: {
    ...mapState('users', ['clientsList', 'usersList', 'status', 'error']),
    ...mapGetters('users', ['isAuthorized', 'role']),
    clients() {
      return this.clientsList.map(({ id: value, name: text }) => ({
        value,
        text
      }))
    },
    filteredUsers() {
      const { usersList, existingUser } = this
      return usersList.filter(user => user.id != existingUser.id)
    },
    existingEmails() {
      return this.filteredUsers.map(user => user.email)
    },
    existingUsernames() {
      return this.filteredUsers.map(user => user.username)
    },
    roleOptions() {
      const options = ['admin', 'manager', 'user']

      if (this.role.name === 'manager') {
        return options.filter(option => option != 'admin')
      }
    
      return options
    },
    isAdminUpgrade() {
      const { currentRole, existingUser } = this
      return currentRole !== 'admin' && existingUser.role === 'admin'
    },
    isUpdatedClient() {
      const { currentRole, currentClientID, existingUser } = this
      return currentRole !== 'admin' && (currentClientID !== existingUser.client_id)
    }
  },
  methods: {
    ...mapActions('users', ['CLEAR_ERROR']),
    onClickSave() {
      const {
        id,
        name,
        email,
        username,
        phone,
        role,
        company,
        show_plan_part_lists,
      } = this.existingUser
      let { client_id } = this.existingUser
      if (role === 'admin') {
        client_id = null
      }
      this.$emit('onUpdate', {
        id,
        name,
        email,
        username,
        phone,
        role,
        company,
        client_id,
        show_plan_part_lists,
       })
    },
    onClickClose() {
      this.resetData()
      this.$emit('cancel')
    },
    validateUniqueEmail() {
      const { existingUser, existingEmails } = this
      this.uniqueEmail =
        !existingEmails.includes(existingUser.email) ||
        'Email already used for another user'
    },
    validateUniqueUsername() {
      const { existingUser, existingUsernames } = this
      this.uniqueUsername =
        !existingUsernames.includes(existingUser.username) ||
        'Username already used for another user'
    },
    resetData() {
      this.CLEAR_ERROR()
    }
  },
  created() {
    this.resetData()
  },
  beforeDestroy() { 
    this.$bus.$off('EDIT_USER')
  },
  mounted() {
    this.resetData()
    this.$bus.$on('EDIT_USER', ({ user }) => {
      this.existingUser = user
      this.currentRole = user.role
      this.currentClientID = user.client_id
    })
  }
}
</script>

<style scoped>
#site-association-alert{
  padding: 8px 12px 8px 12px;
  margin-bottom: 20px;
  background-color: #303030;
}

#alert-text{
  font-size: 12px;
}
</style>

