<template>
  <v-card>
    <v-card-title class="px-0">
      <v-toolbar dense flat color="primary">
        <v-dialog
          v-model="dialogClient"
          persistent
          max-width="500px"
          v-if="isAuthorized('admin')"
        >
          <v-btn flat small slot="activator" data-cy="add-client" light>
            Create Client
            <v-icon right>apartment</v-icon>
          </v-btn>

          <client-edit
            v-if="dialogClient"
            :visible="dialogClient"
            @clickClose="dialogClient = false"
            @onSave="saveClient"
          />
        </v-dialog>
      </v-toolbar>
    </v-card-title>
    <v-card-text class="pa-0">
      <client-table :clients="clientsList"></client-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { ClientEdit } from '@/components/User/Cards'
import ClientTable from '@/components/User/Table/ClientTable'

export default {
  name: 'ClientManagement',
  components: { ClientEdit, ClientTable },
  data: () => ({
    dialogClient: false,
    email: ''
  }),
  computed: {
    ...mapGetters('users', ['isAuthorized']),
    ...mapState('users', ['clientsList', 'status', 'error'])
  },
  methods: {
    async saveClient(client) {
      await this.$store.dispatch('users/SAVE_CLIENT', client)
      if (this.status === 'OK') {
        return setTimeout(() => (this.dialogClient = false), 500)
      }
    }
  },
  watch: {
    dialogClient() {
      this.email = ''
      this.$store.commit('users/SET_STATUS', null)
      this.$store.commit('users/SET_ERROR', null)
    }
  }
}
</script>
