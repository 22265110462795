<template>
  <v-card data-cy="client-card" v-show="visible">
    <v-card-title class="headline sdark">
      <v-layout align-center>
        <v-flex>
          <span class="primary--text text-uppercase font-weight-medium"
            >{{ id ? 'Edit' : 'Create' }} client</span
          >
        </v-flex>
        <v-flex shrink>
          <v-btn
            color="primary"
            icon
            flat
            @click.native="onClickClose"
            data-cy="close"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <v-container grid-list-md>
        <v-layout row wrap align-center>
          <v-flex xs12>
            <v-alert :value="error" outline color="primary">{{
              error
            }}</v-alert>
          </v-flex>
          <v-flex xs12>
            <d-form
              v-model="valid"
              @onSubmit="onClickSave"
              @onCancel="onClickClose"
              includeCancel
              v-bind="{ status, error }"
            >
              <v-text-field
                color="primary"
                name="name"
                label="Name"
                id="name"
                v-model="name"
                :rules="[rules.required]"
                type="text"
              ></v-text-field>
              <v-text-field
                color="primary"
                name="contact"
                label="Contact"
                id="contact"
                type="text"
                v-model="contact"
              />
              <v-text-field
                color="primary"
                name="email"
                label="Email"
                :rules="rules.emailRules"
                id="email"
                type="email"
                v-model="email"
              />
              <v-text-field
                color="primary"
                name="address"
                label="Address"
                id="address"
                type="text"
                v-model="address"
              />
              <v-text-field
                color="primary"
                name="city"
                label="City"
                id="city"
                type="text"
                v-model="city"
              />
              <v-text-field
                color="primary"
                name="state"
                label="State"
                id="state"
                type="text"
                v-model="state"
              />
            </d-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { FormMixin } from '@/components/Mixins'

const fields = ['id', 'name', 'email', 'contact', 'address', 'city', 'state']

const defaultClient = () => fields.reduce((a, c) => ({ ...a, [c]: null }), {})

const props = {
  client: {
    type: Object,
    default: defaultClient
  },
  visible: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'client-edit-card',
  props,
  mixins: [FormMixin],
  data: () => ({
    valid: false,
    ...defaultClient()
  }),
  computed: {
    ...mapState('users', ['status', 'error'])
  },
  methods: {
    ...mapActions('users', ['DISMISS']),
    initialData() {
      this.valid = false
      fields.forEach(k => (this[k] = (this.client && this.client[k]) || null))
    },
    onClickSave() {
      this.$emit(
        'onSave',
        fields.reduce((a, c) => ({ ...a, [c]: this[c] }), {})
      )
    },
    onClickClose() {
      this.resetData()
      this.$emit('clickClose')
    },
    resetData() {
      this.DISMISS()
      this.initialData()
    }
  },
  created() {
    this.resetData()
  },
  watch: {
    client() {
      this.resetData()
    }
  }
}
</script>
