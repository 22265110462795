<template>
  <v-card>
    <v-card-title class="px-0">
      <v-toolbar dense flat color="primary">
        <v-dialog v-model="dialogInvite" persistent max-width="500px" v-if="showInviteButton">
          <v-btn flat small slot="activator" data-cy="invite-user" light>
            Invite
            <v-icon right>mail</v-icon>
          </v-btn>
          <invitation-card @onClickInvite="onClickRegister" @clickClose="dialogInvite = false"></invitation-card>
        </v-dialog>
        <v-dialog
          v-model="dialogCreate"
          persistent
          max-width="500px"
          v-if="isAuthorized('manager')"
        >
          <v-btn flat small slot="activator" data-cy="add-user" light>
            Add User
            <v-icon right>person_add</v-icon>
          </v-btn>
          <user-add-card
            v-if="dialogCreate"
            :visible="dialogCreate"
            :status="status"
            :error="error"
            @close="dialogCreate = false"
          />
        </v-dialog>
      </v-toolbar>
    </v-card-title>
    <v-card-text class="pa-0">
      <UserTable :users="usersList"></UserTable>
    </v-card-text>
  </v-card>
</template>

<script>
import { UserAddCard, InvitationCard } from '@/components/User/Cards'
import { mapGetters, mapState } from 'vuex'
import UserTable from '@/components/User/Table/UserTable.vue'

export default {
  name: 'UserManagement',
  components: { UserAddCard, InvitationCard, UserTable },
  data: () => ({
    dialogCreate: false,
    dialogInvite: false,
    email: ''
  }),
  computed: {
    ...mapGetters('users', ['isAuthorized']),
    ...mapState('users', ['usersList', 'status', 'error', 'client']),
    ...mapState('system', ['user_invite_allowed']),
    showInviteButton() {
      return this.isAuthorized('admin') && this.user_invite_allowed
    }
  },
  methods: {
    async onClickRegister(email) {
      await this.$store.dispatch('users/REGISTER', email)
      if (this.status === 'OK') {
        return setTimeout(() => (this.dialogInvite = false), 500)
      }
    }
  },
  watch: {
    dialogCreateClient() {
      this.email = ''
      this.$store.dispatch('users/DISMISS')
    },
    dialogCreate() {
      this.email = ''
      this.$store.dispatch('users/DISMISS')
    },
    dialogInvite() {
      this.email = ''
      this.$store.dispatch('users/DISMISS')
    }
  }
}
</script>
